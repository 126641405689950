<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <h2>Add Components to Meal</h2>
        <v-toolbar flat>
          <v-btn-toggle
            v-model="componentView">
            <v-btn value="date">Search Production</v-btn>
            <v-btn value="all">Search All</v-btn>
            <v-btn value="create" @click="componentToAdd=null; newComponentId=null">Create New</v-btn>
          </v-btn-toggle>
        </v-toolbar>


        <template v-if="componentView==='date'">
          <v-toolbar flat dense>
            <v-text-field
              :value="datesFormatted"
              label="Select production dates"
              single-line
              hide-details
              readonly
              @click="showDatePicker=true"
              append-icon="mdi-calendar"
              :loading="loading"
            />
            <v-dialog
              v-model="showDatePicker"
              ref="dialog"
              width="290px"
            >
              <v-date-picker
                v-model="datePickerDate"
                :range="isAdmin"
                no-title
                @close="!isAdmin && closeDatePicker()"
                @change="closeDatePicker"
              ></v-date-picker>
              <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
            </v-dialog>
          </v-toolbar>
          <v-toolbar flat dense v-if="filteredComponents.length>0">
            <v-text-field
              label="Search"
              v-model="componentSearch"
              hide-details
              clearable
            />
            <v-spacer/>
            <v-btn-toggle
              multiple
              v-model="selectedTeams"
              dense
              rounded
            >
              <v-btn
                v-for="team of Object.keys(teams)" v-bind:key="team"
                :value="team"
              >
                {{ team }}
              </v-btn>
            </v-btn-toggle>
            <v-spacer/>
          </v-toolbar>
          <v-container style="height: 800px; overflow: scroll">
            <v-dialog v-model="showAddComponent" width="600">
              <v-card v-if="componentToAdd">
                <v-card-title>
                  {{ componentToAdd.name }}&nbsp;
                  <router-link :to="{ name: 'ComponentDetail', params: { id: componentToAdd.id }}">
                    C{{ componentToAdd.id }}
                  </router-link>
                  <v-spacer/>
                </v-card-title>
                <v-card-actions>
                  <!--                  <v-select-->
                  <!--                    label="Meal ID version"-->
                  <!--                    :items="versions"-->
                  <!--                    v-model="mealId"-->
                  <!--                  />-->
                  <v-spacer/>
                  <v-text-field
                    reverse
                    label="Amount(g)"
                    type="number"
                    v-model="amount"
                    prefix="g"
                    @keydown.enter="addToMeal(componentToAdd, amount); showAddComponent=false"
                  />
                  <v-spacer/>
                  <v-btn @click="addToMeal(componentToAdd, amount); showAddComponent=false" color="green" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-text>
                  <!--            <p v-for="(mf,i) of componentToAdd.meal_foods" v-bind:key="i">-->
                  <!--              {{ mf }}-->
                  <!--            </p>-->
                  <MealDetailTable
                    v-if="componentToAdd && selectedComponentIngredients.length > 0"
                    :component="componentToAdd"
                    :ingredients="selectedComponentIngredients"
                    :read-only="true"
                    show-meal-cost
                  />
                  <!--            {{componentToAdd}}-->
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-card
              class="mb-2" v-for="(c,index) of filteredComponents" v-bind:key="index"
              @click="showComponent(c.component)"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <h2>
                      {{ c.component.name }} (C{{ c.component.id }})
                      <v-icon color="blue" v-if="c.component.verified">mdi-shield-check</v-icon>
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    {{ formatWeightWithUnits(c.totalAmount - c.totalAmountAllergy) }}
                  </v-col>
                  <v-col class="text-right">
                    <v-chip outlined v-for="(id,team) of c.teams" v-bind:key="id">{{ team }}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>

              <!--              <v-card-actions>-->
              <!--                <v-text-field-->
              <!--                  reverse-->
              <!--                  label="Amount(g)"-->
              <!--                  v-model="amounts[id]"-->
              <!--                  type="number"-->
              <!--                />-->
              <!--                <v-spacer/>-->
              <!--                <v-btn @click="addToMeal(c.component, amounts[id])" color="green" icon>-->
              <!--                  <v-icon>mdi-plus</v-icon>-->
              <!--                </v-btn>-->
              <!--              </v-card-actions>-->
            </v-card>
          </v-container>
        </template>
        <v-toolbar flat v-if="componentView==='all'">
          <ComponentAutocomplete

            :component.sync="componentToAdd"
          />
        </v-toolbar>
        <v-card v-if="componentView==='create'">
          <v-card-title v-if="!newComponentId">Create Component</v-card-title>
          <v-card-text>
            <ComponentDetail
              :component-id="newComponentId"
              is-admin
              :read-only="false"
              hide-meals
              v-on:update:componentId="newComponentId = $event"
              no-reload-on-add-ingredient
            />
          </v-card-text>
        </v-card>
        <v-card v-if="componentToAdd">
          <v-card-title>
            {{ componentToAdd.name }}
            <v-spacer/>
          </v-card-title>
          <v-card-actions>
            <v-select
              label="Meal ID version"
              :items="versions"
              v-model="mealId"
            />
            <v-spacer/>
            <v-text-field
              reverse
              label="Amount(g)"
              type="number"
              v-model="amount"
            />
            <v-spacer/>
            <v-btn @click="addToMeal(componentToAdd, amount)" color="green" icon>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <!--            <p v-for="(mf,i) of componentToAdd.meal_foods" v-bind:key="i">-->
            <!--              {{ mf }}-->
            <!--            </p>-->
            <MealDetailTable
              v-if="componentToAdd && selectedComponentIngredients.length > 0"
              :component="componentToAdd"
              :ingredients="selectedComponentIngredients"
              :read-only="true"
            />
            <!--            {{componentToAdd}}-->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <h2>Meal</h2>
        <v-alert class="ma-4" type="warning" outlined>
          WARNING: If you set a diet (stream) and a date for the meal, it will appear in the upcoming meal schedule. If
          you wish to design but not 'publish' the meal, leave one of these blank.
        </v-alert>
        <v-container style="height: 800px; overflow: scroll">
          <MealDesign
            hide-add-component
            hide-name-and-analysis
            :is-admin="false"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ComponentAutocomplete from "@/components/ComponentAutocomplete";
import {
  convertIngredient,
  dateFormatted, escapeRegex, formatWeight, formatWeightWithUnits, getProductionDays,
  normaliseIngredient,
} from "@/store/utils";
import MealDetailTable from "@/components/MealDetailTable";
import urlState from "@/router/urlState";
import moment from "moment/moment";
import api from "@/api";
import {mapActions} from "vuex";
import MealDesign from "@/views/MealDesign.vue";
import ComponentDetail from "@/components/ComponentDetail.vue";

export default {
  name: "MealDesigner",
  components: {ComponentDetail, MealDesign, MealDetailTable, ComponentAutocomplete},
  mixins: [urlState],
  props: {
    isAdmin: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      componentToAdd: null,
      productionDate: null,
      amount: 100,
      components: [],
      mealName: null,
      showDatePicker: null,
      datePickerDate: null,
      dates: [],
      availableComponents: [],
      componentView: 'date',
      amounts: {},
      showAddComponent: null,
      loading: null,
      mealId: null,
      saving: null,
      teams: {},
      selectedTeams: [],
      componentSearch: null,
      newComponentId: null
    }
  },
  watch: {
    versions(v) {
      if (v) {
        this.mealId = v.sort().reverse()[0];
      } else
        this.mealId = null;
    },
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = getProductionDays(this.datePickerDate);
        }
      }
    },
    showAddComponent(v) {
      if (v) {
        this.amount = 100;
      }
    },
  },
  mounted() {
    this.syncToUrl({
      param: 'newComponentId', urlParam: 'newComponentId', initFromRoute: true,
      parseCallback: (v) => Number(v) ? v : undefined
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });

    console.log('new component id', this.newComponentId);
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    formatWeightWithUnits,
    formatWeight,
    ...mapActions([
      'fetchMeal',
      'fetchMealIngredients'
    ]),
    dateFormatted,
    fetchData() {
      const dates = this.dates.filter(d => moment(d).isValid());
      if (!(dates && dates.length > 0)) {
        console.log('no dates');
        return;
      }
      this.loading = true;
      const params = {dates, includeZeroAmounts: true};
      console.log('fetching v2/prep', params);
      return api
        .get('v2/prep', {params})
        .then(r => {
          console.log('got', r.data);
          const components = Object.values(r.data.components).sort((e1, e2) => e1.component.name.localeCompare(e2.component.name));
          console.log('sorted', components);
          const teams = {};
          components.forEach(c => {
            c.teams = {};
            c.meals.forEach(m => {
              c.teams[m.team] = m.meal_id;
              teams[m.team] = m.meal_id
            });
          })
          this.availableComponents = components;
          this.teams = teams;
          // this.availableComponents.sort((c1, c2) => c1.name.localeCompare(c2));
          this.amounts = Object
            .fromEntries(
              Object.entries(this.availableComponents)
                .map(([id]) => [id, 0])
            );
        })
        .finally(() => this.loading = false);
    },
    async addToMeal(c, amount) {
      if (!this.editMealId) {
        alert('You need to create a meal first!');
        return;
      }
      this.saving = true;
      console.log('add to meal', c, amount);

      amount = Number(amount);
      await api.post(`v2/component/${c.id}/copy-default-to-meal/${this.editMealId}`, {amount})
      await this.fetchMealIngredients({id: this.editMealId, force: true});

      this.saving = false;
    },
    removeFromMeal(c1) {
      this.components = this.components.filter(c2 => c1.component.id !== c2.component.id);
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    async showComponent(c) {
      this.showAddComponent = true;
      this.componentToAdd = null;
      await api
        .get(`/v2/component/${c.id}`)
        .then(r => {
          console.log('got ', r.data);
          this.componentToAdd = r.data;
        });

    },
    setComponentAmount(foods, amount) {
      console.log('setComponentAmount', amount, foods);
      const currentAmount = foods
        .reduce((sum, mf) => mf.amount + sum, 0);
      const ratioAdjust = amount / currentAmount;
      console.log('ratio to adjust', {ratioAdjust, currentAmount});
      return foods.map(f => ({...f, amount: ratioAdjust * f.amount}));
    },
    createMeal() {
      return api.post('v2/meal', {meal: {name: this.mealName}})
        .then(r => {
          console.log('result', r);
          const meal = r.data;
          const query = {...this.$route.query || {}, meal: meal.id}
          this.$router.push({query});
        })
        .catch(e => console.error('error', e));
    }
  },
  computed: {
    editMealId() {
      return this.$route.params.id;
    },
    selectedComponentIngredients() {
      console.log('componentToAdd', this.componentToAdd);
      if (!this.componentToAdd) {
        return [];
      }
      let foods = (this.componentToAdd.meal_foods || [])
        .filter(mf => mf.mealid === this.mealId);

      if (foods.length === 0) {
        foods = this.componentToAdd.component_foods;
      }


      console.log('foods', foods);
      foods = this.setComponentAmount(foods, this.amount);
      console.log('foods adjusted', foods);

      foods = foods
        .map(normaliseIngredient(null))
        .map(i => ({
          ...i,
          component: this.componentToAdd
        }))
        .map(convertIngredient);
      return foods;
    },
    versions() {
      if (this.componentToAdd && this.componentToAdd.meal_foods) {
        return [...new Set(this.componentToAdd.meal_foods.map(mf => mf.mealid))];
      }
      return [];
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    filteredComponents() {

      let availableComponents = this.availableComponents;
      if (this.componentSearch) {
        console.log('search', this.componentSearch, availableComponents);
        const regex = new RegExp(escapeRegex(this.componentSearch), 'i')
        availableComponents = availableComponents.filter(c => regex.test(c.component.name));
      }
      if (this.selectedTeams && this.selectedTeams.length > 0) {
        console.log('this.selectedTeams', this.selectedTeams);
        availableComponents = availableComponents.filter(c => this.selectedTeams.some(t => !!c.teams[t]));
      }
      return availableComponents;
    }
    ,
    canEditMeal() {
      return this.isAdmin || !!(this.meal && !this.meal.date && !this.readOnly);
    }
  }
}
</script>

<style scoped>

</style>